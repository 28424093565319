import env from './.env';
import { APIURL } from './api.url';
import { IEnv } from './Ienv';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const Accountkit = `https://accountkit-qa.zapopen.com`;
const VoiceVideo = `https://voicevideocall-qa.zapopen.com`;
const Log=`https://log-qa.zapopen.com`;
const DeviceSetting=`https://devicesetting-qa.zapopen.com`;
const DeviceAction=`https://deviceaction-qa.zapopen.com`;
const BulkImport=`https://bulkimportexport-qa.zapopen.com`;
const DataLayer = `https://qa-partner-datalayer.azurewebsites.net`;
const pkPassFile = `https://voicevideocall-qa.zapopen.com`;
const Emailnotification =`https://qa-emailnotification.zapopen.com`;
const ExportData =`https://log-qa.zapopen.com`;

export const environment: IEnv = {
  version: env.app_version,
  build_version: env.app_build_version,
  isVersionCheck: false,
  production: false,
  mode: 'qa',
  SITE_NAME: '',
  COPYRIGHT_TEXT: '©2020',
  DEFAULT_PAGINATION_LIMIT: 10,
  API_URL: APIURL({
    Accountkit,
    VoiceVideo,
    Log,
    DeviceSetting,
    DeviceAction,
    BulkImport,
    DataLayer,
    pkPassFile,
    Emailnotification,
    ExportData
  })
};


import { ImportValidatorService } from './../importvalidator/import-validator.service';
import { Injectable, inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiHandler } from 'src/app/Core/services/api.handler';
import { Observable, catchError, map, of } from 'rxjs';
import { Credentials } from './credentials.model';
import { LocalStorageService } from './local.storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private _localStorage = inject(LocalStorageService);
  private apiHandler = inject(ApiHandler);
  private importValidatorService = inject(ImportValidatorService);

  UserSignout(userid: number) {
    return this.apiHandler
      .get(environment.API_URL.VoiceVideo.UserSignout + userid)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    this._localStorage.setCredentials();
    return of(true);
  }
  validateResetPasswordLink(token: any) {
    return this.apiHandler
      .get(environment.API_URL.VoiceVideo.validateResetPasswordLink + token)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  ResetPassword(_context: any) {
    return this.apiHandler
      .post(environment.API_URL.VoiceVideo.ResetPassword, {
        password: _context.password,
        confirmpassword: _context.confirmpassword,
        PasswordResetToken: _context.PasswordResetToken,
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  ValidateUserName(data: any) {
    return this.apiHandler
      .post(
        environment.API_URL.VoiceVideo.url + '/api/VoiceVideo/IsUsernameValid',
        data
      )
      .pipe(
        map((respose) => {
          return respose;
        })
      )
      .pipe(catchError(this.handleError));
  }
  login(_context: {
    username: string;
    password: string;
    Portal:number;
  }): Observable<Credentials> {
    return this.apiHandler
      .post(environment.API_URL.VoiceVideo.login, {
        Username: _context.username,
        Password: _context.password,
        Portal:_context.Portal

      })
      .pipe(
        map((res) => {
          if (res.user) {
            let obj = {
              authToken: res.AuthToken,
              authKey: res.user.SessionID,
              csrf: res.CsrfToken
            };
            this.importValidatorService.setUser(res.user);
            this._localStorage.setCredentials(obj);
            return res;
          } else {
            return false;
          }
        })
      )
      .pipe
      // catchError((e: { error: { message: any; error_info: any; }; }) => {
      //   e.error.message = e.error.message ? e.error.message : 'Faile to login.';
      //   this.notificationService.notifyError(e.error.error_info ? e.error.error_info : e.error.message);
      //   return of(false);
      // })
      ();
  }

  SignInAsAdmin(data: any): Observable<Credentials> {
    return this.apiHandler
      .post(environment.API_URL.VoiceVideo.SignInAsAdmin, data)
      .pipe(
        map((res) => {
          if (res.user) {
            localStorage.removeItem('credentials');
            let obj = {
              authToken: res.AuthToken,
              authKey: res.user.SessionID,
              csrf: res.CsrfToken
            };
            this.importValidatorService.setUser(res.user);
            this._localStorage.setCredentials(obj);
            return res;
          } else {
            return false;
          }
        })
      )
      .pipe();
  }
  getUserDetails(authkey: any): Observable<any> {
    return this.apiHandler
      .get(`${environment.API_URL.VoiceVideo.getUserDetails}` + authkey)
      .pipe(
        map((res) => {
          if (res) {
            if (res.type == 'error') {
              localStorage.clear();
            } else {
              localStorage.removeItem('credentials');
              let obj = {
                authToken: res.AuthToken,
                authKey: res.user.SessionID,
                csrf: res.CsrfToken
              };
              this.importValidatorService.setUser(res.user);
              this._localStorage.setCredentials(obj); 
            }
          }
          return res;
        })
      );
  }
  getUserDetailsModule(): Observable<any> {
    let authkey = this.getKey();
    let data = {
      authkey: authkey,
    };
    return this.apiHandler
      .post(`${environment.API_URL.VoiceVideo.getUserDetails}`, data)
      .pipe(
        map((res) => {
          if (res) {
            if (res.type == 'error') {
              localStorage.clear();
            } else {
              localStorage.removeItem('credentials');
              this.importValidatorService.setUser(res.user);
            }
          }
          return true;
        })
      );
  }
   GetImportValidatorUser(token: any) {
    return this.apiHandler
      .get(environment.API_URL.VoiceVideo.GetImportValidatorUser + token)
      .pipe(
        map((res) => {
          return res;
        })
      );
    return null;
  }

  public getToken(): string | null {
    return this._localStorage.getToken();
  }
  public getKey(): string | null {
    return this._localStorage.getKey();
  }
  public getAuthId(): string | null {
    return this._localStorage.getAuthId();
  }
  public getCsrfToken(): string | null {
    return this._localStorage.getCsrfToken();
  }
  public isAuthenticated(): boolean {
    const token = this._localStorage.getToken();
    return token != null;
  }

  public getloggedInUser() {
    const savedCredentials = this._localStorage.getCurrentUser();

    return savedCredentials ? savedCredentials : null;
  }

  getSavedLang() {
    return this._localStorage.getSavedLang();
  }

  ForgotPassword(email: string) {
    return this.apiHandler
      .get(`${environment.API_URL.VoiceVideo.ForgotPassword}` + email)
      .pipe(
        map((response) => {
          if (response) {
            return response;
          } else {
            // this.notificationService.notifyError(
            //   response.error ? response.error : 'Failed to get device details'
            // );
            return;
          }
        })
      )
      .pipe(catchError(this.handleError));
  }

  GetDashboard() {
    return this.apiHandler.get(`${environment.API_URL.VoiceVideo.GetDashboard}`)
      .pipe(
        map((response) => {
          if (response) {
            return response;
          } else {
            return null;
          }
        }))
      .pipe(catchError(this.handleError));
  }

  private handleError(error: any) {
    return of(error.error);
  }
}
